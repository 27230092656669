import Vue from "vue";
import VueRouter from "vue-router";
import AuthLayout from "@/layouts/AuthLayout";
// import Login from "@/views/user/Login";
// import Register from "@/views/ingresante/Register";
import authService from "@/services/auth.service";
// import PerfilPage from "@/components/Usuarios/PerfilPage";
import SideBarAlumnoLayout from "@/layouts/SideBarAlumnoLayout";
import ExamenPorAlumno from "@/components/Alumnos/examen/ExamenPorAlumno";
import CursadosAlum from "@/flavor/alumno/views/CursadosAlum";
import InscripcionCursadosAlum from "@/flavor/alumno/views/Inscripciones/InscripcionCursadosAlum";
// import RegisterIngresantesCarrera from "@/flavor/alumno/views/ingresantes/RegisterIngresantesCarrera";
// import RegistrarAlumno from "@/flavor/alumno/views/ingresantes/RegistrarAlumno";
// import InscripcionCarrera from "@/flavor/alumno/views/Inscripciones/carrera/InscripcionCarrera";
import CompletarInscripcion from "@/flavor/alumno/views/Inscripciones/carrera/CompletarInscripcion";
import DocumentacionIngresante from "@/flavor/alumno/views/Inscripciones/documentacion/DocumentacionIngresante";
import PerfilAlumno from "@/flavor/alumno/views/PerfilAlumno";
import RecuperarPassword from "@/views/user/RecuperarPassword";
// import AlumnoCarreraList from "@/flavor/alumno/views/Inscripciones/carrera/AlumnoCarreraList";
import InscripcionesExamenAlumno from "@/flavor/alumno/views/Inscripciones/examen/InscripcionesExamenAlumno";
// import RegistrarAlumnoExistente from "@/flavor/alumno/views/ingresantes/alumnos-existentes/RegistrarAlumnoExistente";
// import TipoRegistro from "@/flavor/alumno/views/ingresantes/TipoRegistro";
import FinInscripcion from "@/flavor/alumno/views/ingresantes/FinInscripcion";
import AlumnoCursadosList from "@/flavor/alumno/views/Inscripciones/cursado/AlumnoCursadosList";
import ProgramaExamenAlumno from "@/flavor/alumno/views/programa-examenes/ProgramaExamenAlumno";
// import RegistrarAlumno from "@/flavor/alumno/views/ingresantes/RegistrarAlumno";
import AlumnoLogin from "@/flavor/alumno/views/AlumnoLogin.vue";
import Profile from "@/viewsNew/user/Profile.vue";
import Veedores from "@/flavor/alumno/views/veedores/Veedores.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Default",
    component: SideBarAlumnoLayout,
    // redirect: "/alumno/inscribir-carrera",
    redirect: "/alumno/inscribir-espacios",
    // beforeEnter: (to, from, next) => {
    //   if(authService.isRol('alumno')){next();}else{next('login')}
    // },
    children: [
      {
        path: "/alumno/veedores",
        name: "veedores",
        component: Veedores,
        props: true,
      },
      {
        path: "/alumno/profile",
        name: "profile",
        component: Profile,
        props: true,
      },
      {
        path: "/alumno/programa-examenes",
        name: "programa-examenes",
        component: ProgramaExamenAlumno,
        props: true,
      },
      // {
      //   path: "/alumno/inscribir-carrera",
      //   name: "inscripcion-carrera",
      //   component: AlumnoCarreraList,
      //   props: true,
      // },
      {
        path: "/alumno/completar-inscripcion/:inscripcion_id",
        name: "completar-inscripcion",
        component: CompletarInscripcion,
        props: true,
      },
      {
        path: "/alumno/subir-documentacion/:inscripcion_id",
        name: "subir-documentacion",
        component: DocumentacionIngresante,
        props: true,
      },

      {
        path: "/alumno/inscribir-curso",
        name: "inscripcion-alumno",
        component: InscripcionCursadosAlum,
        props: true,
      },
      {
        path: "/alumno/inscribir-examen",
        name: "inscripcion-finales",
        component: InscripcionesExamenAlumno,
        props: true,
      },
      {
        path: "/alumno/inscribir-espacios",
        name: "inscribir-espacios",
        component: AlumnoCursadosList,
        props: true,
      },
      {
        path: "/alumno/cursadas",
        name: "cursada-por-alumno",
        component: CursadosAlum,
        props: true,
      },
      {
        path: "/alumno/perfil",
        name: "perfil-alumno",
        component: PerfilAlumno,
        props: true,
      },
      {
        path: "/alumno/examenes",
        name: "estadoExamen",
        component: ExamenPorAlumno,
        props: true,
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthLayout,
    children: [
      {
        path: "recuperar",
        name: "recuperar",
        component: RecuperarPassword,
      },
      {
        path: "login",
        name: "login",
        component: AlumnoLogin,
      },
      // {
      //   path: "register",
      //   name: "register",
      //   component: RegistrarAlumno,
      // },
      // {
      //   path: "tipo-registro",
      //   name: "tipo-registro",
      //   component: TipoRegistro,
      // },
      {
        path: "register-carrera",
        name: "registerCarrera",
        component: FinInscripcion,
      },
      {
        path: "register-alumno-existente",
        name: "registerAlumnoExistente",
        component: FinInscripcion,
      },
    ],
  },
  {
    path: "/login",
    redirect: "/auth/login",
  },
  {
    path: "/register",
    redirect: "/auth/register",
  },
  {
    path: "/tipo-registro",
    redirect: "/auth/tipo-registro",
  },
  {
    path: "/register-carrera",
    redirect: "/auth/register-carrera",
  },
  {
    path: "/register-alumno-existente",
    redirect: "/auth/register-alumno-existente",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name === "recuperar" ||
    to.name === "register" ||
    to.name === "registerAlumnoExistente" ||
    to.name === "registerCarrera" ||
    to.name === "tipo-registro"
  ) {
    next();
  } else if (to.name !== "login" && !authService.isLoggedIn())
    next({ name: "login" });
  else {
    next();
  }
});

const DEFAULT_TITLE = "ESTUDIANTES | UNES";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
